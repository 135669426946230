import ready from "./ready";
const currentScript = document.currentScript;
ready(function () {
    initEmbed();
});
function initEmbed() {
    let embeddables = document.querySelectorAll('[data-onst-embed]:not(.embed-loaded)');
    for (let i = 0; i < embeddables.length; i++) {
        const embedHolder = embeddables[i];
        const embedType = embedHolder.dataset.onstEmbed;
        const embedParam = embedHolder.dataset.onstEmbedParam;
        const baseUrl = getBaseUrl(currentScript.src);
        let embedUrl = baseUrl + '/embed/' + embedType;
        if (embedParam) {
            embedUrl += '?param=' + embedParam;
        }
        let xhr = new XMLHttpRequest();
        xhr.open('GET', embedUrl);
        xhr.send();
        xhr.onload = function () {
            if (xhr.status != 200) {
                console.error(`ERROR: Loading ${embedType}' embed failed. ([${xhr.status}] ${xhr.statusText})`);
                return;
            }
            embedHolder.innerHTML = xhr.response.trim();
            embedHolder.classList.add('embed-loaded');
            let scripts = embedHolder.querySelectorAll("script:not([src])");
            for (var i = 0; i < scripts.length; ++i) {
                let script = scripts[i];
                let newScript = document.createElement('script');
                newScript.innerHTML = script.innerHTML;
                newScript.classList.add('withajax');
                script.parentNode.insertBefore(newScript, script.nextSibling);
            }
            let srcScripts = embedHolder.querySelectorAll("script[src]");
            for (var i = 0; i < srcScripts.length; ++i) {
                let script = srcScripts[i];
                let newScript = document.createElement('script');
                newScript.src = script.src;
                newScript.classList.add('withajax');
                script.parentNode.insertBefore(newScript, script.nextSibling);
            }
        };
        xhr.onerror = function () {
            console.error(`ERROR: Loading ${embedType} embed failed.`);
        };
        console.log('embedType', baseUrl + '/embed/' + embedType);
    }
}
function getBaseUrl(href) {
    let location = document.createElement("a");
    location.href = href;
    // IE doesn't populate all link properties when setting .href with a relative URL,
    // however .href will return an absolute URL which then can be used on itself
    // to populate these additional fields.
    if (location.host == "") {
        location.href = location.href;
    }
    return location.protocol + '//' + location.host;
}
;
